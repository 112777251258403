body, html, .app {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #E0E0E0;
}

/* Webkit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #2c2c2c; /* Background of the scrollbar track */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #5a5a5a; /* Scrollbar color */
  border-radius: 10px;
  border: 3px solid #2c2c2c; /* Border around the thumb, same as track */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4a4a4a; /* Darker shade when hovered */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #5a5a5a #2c2c2c; /* thumb color, track color */
}

/* Internet Explorer and Edge */
body {
  -ms-overflow-style: scrollbar; /* Show scrollbar on IE */
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar-track {
  background-color: #2c2c2c;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #5a5a5a;
  border-radius: 10px;
  border: 3px solid #2c2c2c;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #4a4a4a;
}



/* Main Content */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* About Section */


/* Projects Section */
.projects {
  width: 60%;
  margin-top: 50px;
  text-align: center;
}

.projects h2 {
  font-size: 2rem;
  color: white;
  margin-bottom: 20px;
  position: relative;
}

.projects h2::after {
  content: '';
  display: block;
  width: 100px;
  height: 5px;
  background-color: red;
  margin: 10px auto 0 auto;
  position: relative;
  background-image: url('https://path-to-your-image.png'); /* Add the background image for the project section */
  background-size: cover;
  height: 50px;
}

/* Project Cards */
.project-card {
  display: flex;
  background-color: rgba(36, 36, 36, 0.8);
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
  margin-bottom: 30px;
}

.project-card:hover {
  transform: scale(1.05);
}

.navbar {
  display: flex;
  position: fixed;
  background-color: #2c2c2c;
  padding: 10px;
  z-index: 1000;
}

@media only screen and (max-width: 767px) {
  .navbar {
    display: none;
  }
  .project-list {
    min-width: 300px;
    width: 100%;
    margin-top: 50px;
    text-align: center;
  }
  .projectCard {
    display: flex;
    background-color: rgba(36, 36, 36, 0.8);
    padding: 20px;
    border-radius: 12px;
    width: 95%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease;
    margin-bottom: 30px;
  }
}

.navbar-vertical {
  flex-direction: column;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 15px;
  height: 80%;
  border-radius: 20px;
}

.navbar-horizontal {
  flex-direction: row;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin: 15px;
  width: 800px;
  border-radius: 20px;
}

.navbar-horizontal .nav-item {
  margin: 10px 20px;
}

.navbar-vertical .nav-item {
  margin: 15px 10px;
}

.nav-item .icon {
  width: 40px;
  height: 40px;
  
}

.navbar-vertical .toggle-btn {
  background-color: #2c2c2c;
  border: none;
  cursor: pointer;
  margin-top: auto;
  padding: 0px;
}

.navbar-horizontal .toggle-btn {
  margin-left: auto;
  background-color: #2c2c2c;
  border: none;
  cursor: pointer;
}

.bar {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
  padding: 10px;
  background-color: #1e1e1e;
  border-left: 2px solid #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.5);
  margin-right: 15px;
  border-radius: 20px;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .bar {
    display: none;
  }

}

.controlSection {
  width: 80px;
  margin: 10px 10px 10px 10px;
  padding: 10px;
  background-color: #2a2a2a;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
}

.controlSection:hover {
  background-color: #333;
}


.variableName {
  color: #ccc;
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 0px;
}

.valueControls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controlBtn {
  background-color: #444;
  border: none;
  color: #fff;
  padding: 5px;
  margin: 2px 0;
  width: 24px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.controlBtn:hover {
  background-color: #555;
}

.valueDisplay {
  color: #fff;
  font-size: 16px;
  margin: 5px 0;
  background-color: #2a2a2a;
  width: 40px;
}

.icon {
  width: 50px;
  height: 50px;
  
}

.iconBtn {
  background-color: #1e1e1e;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.textBtn {
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  background-color: #2a2a2a;
  border-radius: 5px;
  padding: 15px 5px;
}

.headerCard {
  background-color: rgba(44, 44, 44, 0.7);
  border-radius: 20px;
  max-width: 40rem;
  width: 90%;
  min-width: 300px;
  margin-bottom: 40px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
};

.headerTitle  {
  margin-top: 14px;
  color: #fff;
};

.headerDescription {
  color: #ddd;
};